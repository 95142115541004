@use '../../UI/colors.scss' as *;
@import '../../UI/Text.scss';

.popupAuth {
  // position: fixed;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  // z-index: 10;
  background-color: $lightPurple;
  color: white;
  // border-radius: 18px;

  display: grid;
  grid-template-columns: 1fr 1fr;

  min-width: 70vw;
  min-height: 75vh;

  // display: none;

  // &_open {
  display: grid;
  //   animation: showPopup .2s;
  // }

  &__image {
    background-image: url(../../Assets/Images/popupAuth_background.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    border-top-right-radius: 18px;
    border-bottom-right-radius: 18px;
  }

  &__info {
    padding-top: 30px;
    padding-bottom: 30px;

    position: relative;

    display: flex;
    flex-direction: column;
    align-items: space-between;
    justify-content: space-between;
  }

  &__cross {
    position: absolute;
    top: 15px;
    right: 20px;
    width: 20px;

    &:hover {
      cursor: pointer;
    }
  }

  &__logo {
    @include decorativeText;
    color: $gold;
    margin-bottom: 30px;
  }

  &__title {
    @include regularText;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
  }

  &__subTitle {
    @include regularText;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    text-align: center;
    color: rgba(255, 255, 255, 0.8);
  }

  &__tg {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__text {
    @include regularText;

    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.019em;
    color: rgba(255, 255, 255, 0.8);

    margin-bottom: 10px;
  }

  &__link {
    color: $gold;
  }

  &__footer {
    .popupAuth__link {
      display: block;
      text-align: center;
    }
  }
}

@keyframes showPopup {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes hidePopup {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    display: none;
  }
}
