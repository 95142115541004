@use '../../UI/colors.scss' as *;
@import '../../UI/Text.scss';

.gpInfo {
  font-family: 'Inter';
  display: grid;
  grid-template-columns: 1fr;

  padding: 40px;
  gap: 25px;

  color: white;

  &__title {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: -0.019em;
    text-align: left;
  }

  &__data {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 4px;

    &_block {
      background-color: $spLightBG;
      padding: 8px 16px;
      display: grid;
      grid-template-columns: 1fr;
    }

    &_who {
      grid-column: 1/3;
    }

    &_where {
      grid-column: 3/5;
    }

    &_difficulty {
      grid-column: 2/4;
    }

    &_level {
      width: 100%;
    }
  }

  &__line {
    font-size: 14px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: -0.019em;
    text-align: left;
    color: white;
    // align-self: center;

    &_data {
      font-weight: 400;
      color: rgba(255, 255, 255, 0.6);
      margin-left: 5px;
      align-self: start;
    }
  }

  &__iconGroup {
    display: flex;
    align-self: start;
  }

  &__text {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 0.6);
    white-space: pre-line;
  }

  &__tags {
    display: flex;
    gap: 6px;
  }

  &__tag {
    background-color: $spLightBG;
    text-align: center;
    padding: 4px 12px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.019em;
    color: rgba(255, 255, 255, 0.6);
  }

  &__btn {
    text-align: center;
    padding: 8px 55px;
    color: black;
    background-color: $gold;
    justify-self: flex-start;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
  }
}

.player {
  background-color: $spLightBG;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 28% auto;

  &__photo {
    width: 100%;
    object-fit: cover;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
  }

  &__info {
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  line-height: 24px;
  letter-spacing: -0.019em;
  text-align: left;

  &__name {
    font-size: 16px;
    font-weight: 600;
    color: rgba(177, 197, 255, 1);
  }

  &__username {
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
  }
}
