@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.App {
}

.App-header {
  background-color: #00192c;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.container {
  padding: 0 50px;
}
html {
  position: relative;
  min-height: 100%;
  background-color: black;
}

.page-enter {
  opacity: 0;
}
.page-exit {
  opacity: 1;
}
.page-enter-active {
  opacity: 1;
}
.page-exit-active {
  opacity: 0;
}
.page-enter-active,
.page-exit-active {
  transition: opacity 0.44s;
}
