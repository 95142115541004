@mixin regularText {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: white;
}

@mixin headerText {
  font-family: 'Inter', sans-serif;
  color: white;
  font-size: 82px;
  font-weight: 500;
  line-height: 62px;
  letter-spacing: -0.019em;
}

@mixin decorativeText {
  font-family: 'Cinzel Decorative', cursive;
  font-size: 32px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
}
