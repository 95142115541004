$darkBlue: rgba(7, 12, 15, 1);

$blue: rgba(3, 3, 31, 1);
$darkPurple: rgba(51, 45, 65, 1);
$lightPurple: rgba(31, 31, 54, 255);

$gray: rgba(37, 35, 37, 1);
$gold: rgba(242, 222, 161, 1);
$yellow: rgba(239, 223, 150, 1);
$green: rgba(57, 87, 77, 1);

$background: rgba(18, 19, 22, 1);
$lightBG: rgba(30, 31, 34, 1);
$spLightBG: rgba(39, 40, 43, 1);

$telegram: rgba(106, 166, 229, 1);
