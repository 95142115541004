.calendar {
  background: rgba(18, 19, 22, 255);

  @media (min-width: 900px) {
    display: grid;
    // grid-template-columns: 15vw auto;
    grid-template-columns: auto;
  }

  &__week {
    display: flex;
    flex-direction: column;
    column-gap: 90px;
    min-height: calc(100vh - 72px);
  }
}
