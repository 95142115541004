@import '../../UI/Text.scss';

.filters {
  background: rgba(18, 19, 22, 255);
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-left: none;

  @media (max-width: 900px) {
    width: 100vw;
    min-height: 100vh;
    padding-top: 4rem;
  }

  user-select: none;
}

.accordion {
  &__title {
    @include regularText;
    font-weight: 500;
  }

  &__btnGroup {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
  }

  &__day {
    text-align: center;
    padding: 8px;
    border-radius: 4px;
    background-color: #2c2d30;
    color: #ffffff99;

    &_active {
      background-color: #b1c5ff;
      color: black;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
