@use '../../UI/colors.scss' as *;
@import '../../UI/Text.scss';

.calendarDay {
  padding: 10px 40px;
  gap: 20px;

  &__title {
    background: rgba(30, 31, 34, 255);

    color: white;
    @include regularText;
    display: inline-block;
    padding: 8px 16px;
    border-radius: 4px;

    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.019em;
  }

  &__games {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
  }

  &_today {
    background-color: rgba(239, 223, 150, 0.6);
  }

  &__warning {
    @include regularText;
    color: gray;
  }
  // margin-bottom: 50px;
}
